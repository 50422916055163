var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "horizon",
          inlineLabel: true,
          dense: true,
          height: _vm.tabHeight,
          tabItems: _vm.tabItems,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    popupParam: _vm.popupParam,
                    nearAccident: _vm.nearAccident,
                    isOld: _vm.isOld,
                    height: _vm.tabHeight,
                    attachInfo1: _vm.attachInfo1,
                    attachInfo2: _vm.attachInfo2,
                    isApprDisabled: "",
                  },
                  on: {
                    "update:nearAccident": function ($event) {
                      _vm.nearAccident = $event
                    },
                    "update:near-accident": function ($event) {
                      _vm.nearAccident = $event
                    },
                    getDetail: _vm.getDetail,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }